import * as React from 'react';
import { TrintStatsForUser } from 'users/queries';
import { Table, TableRow } from 'shared/components';
import UserStatsListItem from './UserStatsListItem';
import { TableCellItem } from 'shared/components/TableRow';

interface Props {
  refetch: () => Promise<any>;
  colWidths: string[];
  headers: TableCellItem[];
  users: TrintStatsForUser[];
}

class UserStatsList extends React.Component<Props> {
  render() {
    const {
      users = [],
      refetch,
      headers,
      colWidths = [],
    } = this.props;
    return (
      <>
        <Table headers={headers} colWidths={colWidths}>
          {users.map((user) => (
            <TableRow<TrintStatsForUser>
              key={user.userId}
              item={user}
              refetch={refetch}
              component={UserStatsListItem}
            />
          ))}
        </Table>
      </>
    );
  }
}

export default UserStatsList;

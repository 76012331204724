import * as React from 'react';
import UserStatsList from './UserStatsList';
import { QueryRouteComponentProps } from 'shared/components/QueryRoute';
import ListViewLayout from 'shared/layout/ListViewLayout';
import { createStyles, Theme } from '@material-ui/core';
import { createStyled } from 'shared/hocs';
import * as create from 'shared/components/Table';
import { Query } from 'react-apollo';
import { GlobalCircleLoader, ListPage } from 'shared/components';
import { TrintStatsForUser } from 'users/queries';
import { ListPageRenderProps, SortDirection } from 'shared/components/ListPage';
import gql from 'graphql-tag';

interface QueryDefinition {
  daysFrom?: number;
  order: SortDirection;
  sort: string;
  perPage: number;
  page: number;
}

interface QueryVars {
  daysFrom: number;
  groupId: string;
  page: number;
  limit: number;
  order: SortDirection;
  sortBy?: string;
}

interface State {
  value: number;
}

interface QueryRes {
  getRecentTrintStatsByUser: TrintStatsForUser[];
}

const GET_RECENT_USAGE = gql`
  query getRecentTrintStatsByUser($groupId: String!, $daysFrom: Int) {
    getRecentTrintStatsByUser(groupId: $groupId, daysFrom: $daysFrom) {
      username
      userId
      trintCount
      duration
      firstCreatedAt
      lastCreatedAt
    }
  }
`;

const colWidths = ['30%', '15%', '15%', '15%', '15%', '15%'];

interface SearchQueryOptions {
  daysFrom: number;
}

type Props = QueryRouteComponentProps<QueryDefinition> &
  ListPageRenderProps<SearchQueryOptions>;

class Inner extends React.Component<Props, State> {
  render() {
    const { paging, searchValues, match } = this.props;
    const { daysFrom = 7 } = searchValues;

    // NB: in v1 of this tab there is no re-ordering or paging of the data, but these fields are left in place for possible future improvements.
    const { order, sortBy, page, perPage: limit } = paging;

    const headers = [
      create.header('Username', 'username'),
      create.header('UserId', 'userId'),
      create.header('Trint Count', 'trintCount'),
      create.header('Total Duration', 'duration'),
      create.header('First At', 'firstCreatedAt'),
      create.header('Last At', 'lastCreatedAt'),
    ];

    const { groupId } = match.params;

    return (
      <Style>
        {({ classes }) => (
          <ListViewLayout>
            <h2>Files Created in the last {daysFrom} days:</h2>
            <Query<QueryRes, QueryVars>
              query={GET_RECENT_USAGE}
              fetchPolicy="cache-and-network"
              variables={{
                page,
                order,
                limit,
                sortBy,
                groupId,
                daysFrom,
              }}
            >
              {({ data, error, loading, refetch }) => {
                if (error) return <span>{error.message}</span>;
                if (loading) return <GlobalCircleLoader show />;
                return (
                  <>
                    <UserStatsList
                      colWidths={colWidths}
                      headers={headers}
                      refetch={refetch}
                      users={data!.getRecentTrintStatsByUser}
                    />
                    <GlobalCircleLoader show={loading} />
                  </>
                );
              }}
            </Query>
          </ListViewLayout>
        )}
      </Style>
    );
  }
}

interface SearchVars {
  daysFrom: number;
}

const sortKeys = ['username', 'trintCount', 'firstCreatedAt', 'lastCreatedAt'];
const RecentTrintStatsByUser: React.SFC<Props> = (props) => {
  const searchKeys: Array<keyof SearchVars> = ['daysFrom'];
  return (
    <ListPage<SearchVars>
      sortKeys={sortKeys}
      searchKeys={searchKeys}
      defaultSortBy="created"
    >
      {(query) => <Inner {...query} {...props} />}
    </ListPage>
  );
};

export default RecentTrintStatsByUser;

const Style = createStyled((theme: Theme) =>
  createStyles({
    searchInput: {
      borderRadius: theme.shape.borderRadius,
      borderStyle: 'solid',
      borderWidth: 1,
      borderColor: theme.palette.grey[500],
      padding: theme.spacing.unit,
    },
    csvBar: {
      float: 'right',
    },
  }),
);

import { GroupMember } from 'types';

const clean = (truthy: string) => (truthy ? truthy : '');

export default function getMembersCsv(members: GroupMember[]): string {
  return members.reduce((acc, member) => {
    const {
      _id,
      username,
      organisationAdmin,
      profile,
      currentTeam,
      reporting,
      teamName,
      disabled,
    } = member;

    const { costCode = '' } = reporting ? reporting : { costCode: '' };
    const { firstName, lastName, jobTitle } = profile;
    const userBase = `${username},${_id}`;
    const userProfile = `${clean(firstName)},${clean(lastName)},${clean(
      jobTitle,
    )}`;
    const groupBase = `${clean(currentTeam)},${clean(teamName ?? '')},${clean(
      costCode,
    )},${organisationAdmin ? 'Admin' : ''}, ${disabled}`;

    return `${acc}${userBase},${userProfile},${groupBase}\n`;
  }, 'Username,UserId,First Name,Last Name,Job Title,Team ID,Team Name,Cost Code,Admin,Account Disabled\n');
}

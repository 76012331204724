import * as React from 'react';
import { TableRow, TableCell, Theme, createStyles } from '@material-ui/core';
import { FormatDuration, FormatDate } from 'shared/components';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import { TrintStatsForUser } from 'users/queries';
import { createStyled } from 'shared/hocs';

interface Props {
  refetch: () => Promise<void>;
  userId: string;
  item: TrintStatsForUser;
  onRowClick: (
    e: React.MouseEvent<HTMLTableRowElement>,
    item: TrintStatsForUser,
  ) => void;
}

function UserListItem(props: Props) {
  const { item } = props;

  return (
    <Styled>
      {({ classes }) => (
        <TableRow>
          <TableCell className={classes.tableCellPadding}>
            <Link
              to={`/users/${item.userId}/files`}
              title="Files"
              className={classnames(classes.link)}
            >
              {item.username}
            </Link>
          </TableCell>
          <TableCell>{item.userId}</TableCell>
          <TableCell>{item.trintCount}</TableCell>
          <TableCell>
            <FormatDuration duration={item.duration} />
          </TableCell>
          <TableCell>
            <FormatDate date={item.firstCreatedAt} />
          </TableCell>
          <TableCell>
            <FormatDate date={item.lastCreatedAt} />
          </TableCell>
        </TableRow>
      )}
    </Styled>
  );
}

const Styled = createStyled((theme: Theme) =>
  createStyles({
    width: {
      width: '100%',
    },
    capitalise: {
      textTransform: 'capitalize',
    },
    tableCellPadding: {
      padding: '4px 0px 4px 24px',
    },
    adminIcon: {
      display: 'inline-flex',
      flex: '0 0 auto',
      verticalAlign: 'middle',
      paddingRight: 3,
    },
    link: {
      textDecoration: 'none',
      color: theme.palette.primary.main,
      '&visited': {
        textDecoration: 'none',
      },
      '&:hover': {
        textDecoration: 'underline',
      },
    },
    tooltipPlacementTop: {
      margin: '0 0 3px 0',
    },
    tooltipPopper: {
      '&[x-placement*="top"] ': {
        opacity: 1,
      },
    },
    lightTooltip: {
      backgroundColor: '#aaaaaa',
      color: theme.palette.text.primary,
      boxShadow: theme.shadows[1],
      fontSize: 12,
    },
    textOverflow: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  }),
);

export default UserListItem;
